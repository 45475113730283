<template>

  <div class="main-layout">

    <iframe allow="camera *;microphone *" class="frame-style"  width="100%" height="100%" allowfullscreen="true"  src="https://uri.amap.com/navigation?from=112.961,28.188,我的位置&to=112.96185,28.188005,图书馆位置&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0">
    </iframe>

  </div>

</template>

<script>
export default {
  name: "guide"
}
</script>

<style scoped>
.main-layout {
  width: 100%;
  height: 100%;
}

</style>
